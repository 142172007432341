<template>
	<div class="pui-form">
		<!-- <ship-modals :modelName="modelName"></ship-modals> -->
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<ship-form-header :modelPk="modelPk"></ship-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('form.ship.tabs.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#cert'" v-if="!isCreatingElement && hasShipCertFunctionality">{{ $t('form.ship.tabs.cert') }}</v-tab>
				<v-tab v-if="!isCreatingElement && showDocuments" :key="2" :href="'#documents'">{{ $t('pui9.documents.tab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-flex xs12>
						<ship-form-main-tab
							:modelName="modelName"
							:parentObject="this.model"
							:formDisabled="formDisabled"
							:isCreatingElement="isCreatingElement"
						></ship-form-main-tab>
					</v-flex>
				</v-tab-item>
				<v-tab-item :key="1" lazy :value="'cert'" v-if="!isCreatingElement && hasShipCertFunctionality">
					<v-flex xs12>
						<pui-master-detail
							componentName="shipcertgrid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ id: 'shipid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:parentModel="model"
						></pui-master-detail>
					</v-flex>
				</v-tab-item>
				<v-tab-item :key="2" :value="`documents`" v-if="!isCreatingElement && showDocuments">
					<pui-master-detail componentName="PuiDocumentGrid" :parentModelName="modelName" :parentPk="pk"></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import ShipFormHeader from './ShipFormHeader.vue';
import ShipFormMainTab from './ShipFormMainTab.vue';
import ShipcertGrid from '../shipcert/ShipcertGrid.vue';

export default {
	name: 'ship-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		ShipFormHeader,
		ShipFormMainTab,
		ShipcertGrid
	},
	data() {
		return {
			modelName: 'ship'
		};
	},
	methods: {},
	computed: {
		hasShipCertFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_SHIPCERT');
		}
	},
	created() {}
};
</script>
